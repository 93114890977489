<script setup lang="ts">
import { type SeriesBlockFragment } from '~/generated/operations'

const props = defineProps<{
	block: SeriesBlockFragment
}>()
</script>
<template>
	<CollectionSeries
		:slug="props.block.series[0].slug!"
		:prefix="block.prefix"
		:heading="block.heading"
		:description="block.description"
	/>
</template>
